import Cookies from "js-cookie";
import { getCondition } from "@/api/valve";
import { saveUserSetting } from "@/api/user";
import { deepClone } from "@/utils";

function saveRefrigerant() {
  const { Refrigerant, CollectionRefrigerantList } = state;
  const RefrigerantParams = `${Refrigerant};${CollectionRefrigerantList.toString()}`;
  const params = { Refrigerants: RefrigerantParams };
  saveUserSetting(params).then((res) => {
    console.log("保存成功", res);
  });
}

function getLocalSafetycerSelect() {
  const localSelect = localStorage.getItem("SafetycerSelect");
  if (localSelect) {
    const list = localSelect.split(",");
    const localList = list.map((item) => {
      let newItem = item;
      const index = item.indexOf("+");
      if (index !== -1) {
        newItem = item.replace("+", ",");
      }
      return newItem;
    });
    return localList;
  }
  return [];
}

const state = {
  serialCondition: {},
  asyncCondition: {},
  systemAsyncState: {},
  Refrigerant: Cookies.get("Refrigerant") || "",
  CollectionRefrigerantList: Cookies.get("CollectionRefrigerantList")
    ? JSON.parse(Cookies.get("CollectionRefrigerantList"))
    : [],
  syncSerialCondition: {},
  safetycerSelect: getLocalSafetycerSelect(),
  valveConditionMap: {},
};

const mutations = {
  SET_REFRIGERANT: (state, Refrigerant) => {
    Cookies.set("Refrigerant", Refrigerant);
    state.Refrigerant = Refrigerant;
  },
  SET_COLLECTION_REFRIGERANT_LIST: (state, collectionList) => {
    const collection = JSON.stringify(collectionList);
    Cookies.set("CollectionRefrigerantList", collection);
    state.CollectionRefrigerantList = collectionList;
  },
  SET_SYSTEM_ASYNC_STATE: (state, { systemId, asyncState }) => {
    if (!state.systemAsyncState[systemId]) {
      state.systemAsyncState[systemId] = false;
    }
    state.systemAsyncState[systemId] = asyncState;
  },
  SET_SERIAL_CONDITION: (state, option) => {
    const { language, UnitId, conditionInfo, serialName } = option;
    if (!state.serialCondition[language]) {
      state.serialCondition[language] = {};
    }
    if (!state.serialCondition[language][UnitId]) {
      state.serialCondition[language][UnitId] = {};
    }
    if (!state.serialCondition[language][UnitId][serialName]) {
      state.serialCondition[language][UnitId][serialName] = {};
    }
    state.serialCondition[language][UnitId][serialName] = conditionInfo;
  },
  SET_ASYNC_CONDITION: (state, { systemId, condition }) => {
    if (!state.asyncCondition[systemId]) {
      state.asyncCondition[systemId] = {};
    }
    state.asyncCondition[systemId] = condition;
  },
  SET_SYNC_SERIAL_CONDITION: (state, option) => {
    const { condition, serialId, Unitid } = option;
    if (!state.syncSerialCondition[Unitid]) {
      state.syncSerialCondition[Unitid] = {};
    }
    if (!state.syncSerialCondition[Unitid][serialId]) {
      state.syncSerialCondition[Unitid][serialId] = {};
    }
    const cloneCondition = deepClone(condition);
    state.syncSerialCondition[Unitid][serialId] = cloneCondition;
  },
  SET_SAFETYCER_SELECT(state, list) {
    const localList = list.map((item) => {
      let newItem = item;
      const index = item.indexOf(",");
      if (index !== -1) {
        newItem = item.replace(",", "+");
      }
      return newItem;
    });
    localStorage.setItem("SafetycerSelect", localList.toString());
    state.safetycerSelect = list;
  },
  SET_VALVE_CONDITION_MAP(state, { valveName, condition }) {
    if (!state.valveConditionMap[valveName]) {
      state.valveConditionMap[valveName] = {};
    }
    state.valveConditionMap[valveName] = condition;
  },
};

const actions = {
  getCondition({ state, rootState, commit }, options) {
    const { serialId, serialName, valveName, valveId } = options;
    const language = rootState.app.language;
    const basicsUnitId = rootState.app.Unitid;
    const customUnitId = rootState.app.customUnitId;
    console.log("getCondition", basicsUnitId, customUnitId);
    return new Promise((resolve) => {
      const UnitId = customUnitId ? customUnitId : basicsUnitId;
      if (
        state.serialCondition[language] &&
        state.serialCondition[language][UnitId] &&
        state.serialCondition[language][UnitId][serialName]
      ) {
        resolve(state.serialCondition[language][UnitId][serialName]);
      } else {
        const params = {
          valveId,
          serialId,
        };
        console.log(params);
        getCondition(params).then((res) => {
          let condition = {};
          let fields = [{ name: "Refrigerant", option: "refrigerantList" }];
          let removeFields = ["Refrigerant", "Pos"];
          const { serialResult } = res.Data;
          serialResult.forEach((field) => {
            if (field.options && field.options.length > 1) {
              const defaultIndex = field.options.findIndex(
                (option) => option.name === field.option
              );
              const defaultOption = field.options[defaultIndex];
              if (
                ["CBVT", "BCV", "DPF(R)"].includes(serialName) &&
                field.name === "Tk"
              ) {
                field.labelType = "value";
                field.label = defaultOption.title;
                condition[`${field.name}Index`] = 2;
              } else {
                field.labelType = "select";
                condition[`${field.name}Index`] = defaultIndex;
              }
              condition[field.name] = defaultOption.value;
              condition[`${field.name}Unit`] = defaultOption.unit;
            } else if (field.options && field.options.length === 1) {
              if (field.values && field.values.length == 1) {
                field.labelType = "checkbox";
                condition[`${field.name}IsChecked`] =
                  field.values[0] === "true" ? true : false;
              } else {
                field.labelType = "value";
              }
              field.label = field.options[0].title;
              condition[field.name] = field.options[0].value;
              condition[`${field.name}Unit`] = field.options[0].unit;
              // console.log(field.name, field.options[0].unit)
            } else if (field.values.length > 1) {
              field.labelType = "radio";
              // 四通换向阀特殊处理
              if (valveName === "Mv4") {
                // field.values = field.values.slice(1).reverse();
              } else if (
                ["Txv", "TxvOld"].includes(valveName) &&
                field.name !== "EqualType"
              ) {
                field.values = field.values.reverse();
              }
              condition[field.name] = Number(field.value);
            } else if (["Refrigerant", "Pos"].includes(field.name)) {
              condition[field.name] = field.value;
            }
            // 设置单位部分类型
            if (
              field.unitOptions &&
              field.unitOptions[field.option].length > 1
            ) {
              field.unitType = "select";
            } else if (
              field.unitOptions &&
              field.unitOptions[field.option].length === 1
            ) {
              field.unit = field.unitOptions[field.option][0];
            }
            field.disabled = false;
            //CBVT DPF(R) CBVT  特别设置 跨临界产品
            if (
              (valveName === "Bv" && serialName !== "CBVT") ||
              (valveName === "Eev" && serialName !== "DPF(R)") ||
              (valveName === "Cvs" && serialName !== "BCV")
            ) {
              removeFields.push("Lnyl");
            }
            if (!removeFields.includes(field.name)) {
              fields.push(field);
            }
          });
          const filterFields = fields.filter((field) => {
            if (
              !["Eev", "TxvOld", "Txv"].includes(valveName) &&
              ["Dp1", "Dp2"].includes(field.name)
            ) {
              return false;
            } else {
              return true;
            }
          });
          const conditionInfo = { fields: filterFields, condition };
          commit("SET_SERIAL_CONDITION", {
            language,
            UnitId,
            conditionInfo,
            serialName,
          });
          console.log(conditionInfo);
          resolve(conditionInfo);
        });
      }
    });
  },
  saveCustomUnitCondition({ commit, rootState }, options) {
    const { customUnitId, condition, fields, serialName } = options;
    const language = rootState.app.language;
    const UnitId = customUnitId;
    const conditionInfo = { condition, fields };
    commit("SET_SERIAL_CONDITION", {
      language,
      UnitId,
      conditionInfo,
      serialName,
    });
  },
  // 同步工况
  setAsyncCondition({ commit }, { systemId, condition }) {
    commit("SET_ASYNC_CONDITION", { systemId, condition });
  },
  getAsyncCondition({ state }, systemId) {
    return new Promise((resolve, reject) => {
      if (state.asyncCondition[systemId]) {
        resolve(state.asyncCondition[systemId]);
      } else {
        reject(null);
      }
    });
  },
  setValveSyncCondition({ commit, state, rootState }, option) {
    const { valveName, condition, isDefault } = option;
    const Unitid = rootState.app.Unitid;
    const customUnitId = rootState.app.customUnitId;
    const curUnitId = customUnitId || Unitid;
    const key = `${curUnitId}-${valveName}`;
    const stateConditon = state.valveConditionMap[key];
    if (!stateConditon && !isDefault) return false;

    commit("SET_VALVE_CONDITION_MAP", { valveName: key, condition });
  },
  getValveSyncConditon({ state, rootState }, { valveName }) {
    const Unitid = rootState.app.Unitid;
    const customUnitId = rootState.app.customUnitId;
    const curUnitId = customUnitId || Unitid;
    const key = `${curUnitId}-${valveName}`;
    const conditon = state.valveConditionMap[key];
    return conditon || null;
  },
  setSafetycer({ commit }, list) {
    const safetycerlist = list.sort();
    commit("SET_SAFETYCER_SELECT", safetycerlist);
  },
  setSystemAsync({ commit }, { systemId, asyncState }) {
    commit("SET_SYSTEM_ASYNC_STATE", { systemId, asyncState });
  },
  getSystemAsync({ state }, systemId) {
    return new Promise((resolve, reject) => {
      if (state.systemAsyncState[systemId]) {
        resolve(state.systemAsyncState[systemId]);
      } else {
        reject(false);
      }
    });
  },
  // 系列工况
  saveSerialCondition({ rootState, commit }, option) {
    const Unitid = rootState.app.Unitid;
    const customUnitId = rootState.app.customUnitId;
    const curUnitId = customUnitId || Unitid;
    console.log("saveSerialCondition", curUnitId, option);
    commit("SET_SYNC_SERIAL_CONDITION", { ...option, Unitid: curUnitId });
  },
  getSyncSerialCondition({ state, rootState }, serialId) {
    const Unitid = rootState.app.Unitid;
    const customUnitId = rootState.app.customUnitId;
    const curUnitId = customUnitId || Unitid;
    return new Promise((resolve) => {
      if (
        !state.syncSerialCondition[curUnitId] ||
        !state.syncSerialCondition[curUnitId][serialId]
      ) {
        resolve(null);
      } else {
        resolve(state.syncSerialCondition[curUnitId][serialId]);
      }
    });
  },
  // 制冷剂相关
  setDefaultRefrigerant({ commit, rootState }, Refrigerant) {
    commit("SET_REFRIGERANT", Refrigerant);
    const { token } = rootState.user;
    if (token) {
      saveRefrigerant();
    }
  },
  setCollectionList({ commit, rootState }, collectionList) {
    commit("SET_COLLECTION_REFRIGERANT_LIST", collectionList);
    const { token } = rootState.user;
    if (token) {
      saveRefrigerant();
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
